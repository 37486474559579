<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
    <v-card-title class="elevation-1">
            <v-layout wrap>
              <v-flex xs12 sm6 md8 text-left align-self-center>
                <span> Subscribers </span>
              </v-flex>
              <v-flex md4 align-self-center>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6 lg6 xl6 align-self-center>
                    <v-btn
                      tile
                      class="px-5"
                      small
                      outlined
                      :href="ipURL + '/home/subscribe/getlistpdf'"
                      target="_blank"
                      color="warning"
                      style="text-transform: none"
                    >
                      <span
                        style="
                          font-size: 14px;
                          font-family: poppinssemibold;
                          color: #000;
                        "
                      >
                        Download Pdf</span
                      >
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 xl6 align-self-center>
                         <v-btn
                      tile
                      small
                      outlined
                      color="warning"
                      style="text-transform: none"
                    >
                       <download-excel :data="user" :fields="json_fields">
                      <span
                        style="
                          font-size: 14px;
                          font-family: poppinssemibold;
                          cursor: pointer;
                          color: #000;
                        "
                      >
                        Download Excel</span
                      >
                    </download-excel>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-layout wrap justify-start v-if="user">
            <template v-for="(item, i) in user">
              <v-flex pa-6 xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
                <v-layout wrap>
                  <v-flex xs12 sm12 md12 lg6>
                    <v-layout wrap justify-center>
                      <v-flex xs6 sm8 md8 lg8 xl8>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Email
                            </span>
                          </v-flex>
                          <v-flex text-left xs12>
                            <span style="font-size: 16px">
                              {{ item.email }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm4 md4 lg4 xl4>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Subscribe Date
                            </span>
                          </v-flex>
                          
                          <v-flex text-left xs12>
                            <span style="font-size: 16px">
                              {{ item.subscribedate }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 md12 lg6 align-self-end>
                    <v-layout wrap justify-end fill-height>
                      <v-flex pt-5 xl4 xs6 sm6 md4 lg4 justify-end>
                        <v-dialog v-model="item.delete" max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              tile
                              block
                              small
                              outlined
                              color="warning"
                              style="text-transform: none"
                              v-on="on"
                              v-bind="attrs"
                            >
                              <span style="color: #000; font-size: 14px">
                                Delete
                              </span>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title
                              >Are you sure you want to delete this
                              Subscribtion?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="item.delete = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteItem(item)"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 xl11 :key="i">
                <v-divider></v-divider>
              </v-flex>
            </template>
          </v-layout>
          <v-layout wrap justify-center v-if="pages > 1">
            <v-flex xs12>
              <v-pagination
                v-model="currentPage"
                :length="pages"
                :total-visible="7"
                color="#FF0000"
              ></v-pagination>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center v-if="!user">
            <v-flex xs12>
              <span style="font-size: 25px; color: #000000">
                Oops! No DataFound
              </span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      search: "",
      msg: "",
      currentPage: 1,
      showsnackbar: false,
      ServerError: false,
      offer: "",
      pages: 0,
      editingitem: "",
      editdialog: false,
      count: 10,
      totalData: 0,
      totalRows: 0,
      user: [],
       json_fields: {
        Email: "email",
        "Subscribed Date": "subscribedate",
      },
      
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    search() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/subscribe/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          keyword: this.search,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
             var vm=this
            this.user.forEach(function (itm) {
                itm.subscribedate = vm.formatDate(itm.subscribedate);
             
            });
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["email"] = r.email;
      axios({
        url: "/home/subscribe/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
